@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import 'theme.css';

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}
/* body {
  padding-top: 145px;
} */
.page-enter {
  opacity: 0;
  transform: scale(1.1);
}
button:focus {
  outline: none !important;
}
.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.modalclass{
  z-index: 99999 !important;
}
.myhelpText{
  color: #ff0000 !important;
  width:100%;
}
.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.customeTheme {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: 0 2px 10px 0 rgba(211, 211, 211, 0.5);
}

.customeTheme.place-top:after {
  border-top-color: #fff !important;
}
.customeTheme.place-bottom:after {
  border-bottom-color: #fff !important;
}
.customeTheme.show {
  opacity: 1;
}
#sampleValue {
  -webkit-appearance: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.overflowAuto {
  overflow: auto;
  height: 100%;
  padding-left: 40px;
  padding-right: 30px;
}
.overflowAuto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.overflowAuto::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.overflowAuto::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.helpicon {
  width: 10px;
  height: 10px;
  background-color: var(--light-cyan-blue);
  border-radius: 100%;
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  margin: 0 5px;
}
.helpicon:before {
  content: 'i';
  font-size: 7px;
  line-height: 10px;
  position: absolute;
  color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.MuiTooltip-tooltip {
  background-color: #fff !important;
  box-shadow: 0 10px 21px 6px rgba(75, 147, 253, 0.1);
  color: var(--dark-charcoal-grey) !important;
  padding: 25px !important;
  line-height: 1.5 !important;
  max-width: auto !important;
}
.MuiTooltip-tooltip p {
  line-height: normal;
}
.MuiTooltip-tooltip img{
  max-width: 100%;
}
.MuiTooltip-tooltip * {
  color: var(--dark-charcoal-grey) !important;
}
.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}
.tooltip-arrow {
  border-top: 5px solid #062e56;
}
label[data-shrink='false'] img {
  width: 11px !important;
}
label[data-shrink='true'] img {
  width: 15px !important;
}

.MuiExpansionPanelSummary-root {
  flex-direction: row-reverse;
  padding-left: 0 !important;
}
.MuiExpansionPanel-root {
  box-shadow: none !important;
  background-color: transparent !important;
}
/* .MuiCollapse-container {
  display: none !important;
} */

.MuiExpansionPanel-root:first-child:before {
  display: none;
}
.MuiCollapse-wrapperInner {
  text-align: left;
}
.MuiExpansionPanelDetails-root {
  flex-direction: column;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}
.img-responsive{
  height: 100% !important;
  width: 100% !important;
}
.scrollbar-class{
  height: 84vh;
  overflow-y: scroll;
}

.import_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  width: 6.1vw;
}

.rightContainer {
  width: 70.8vw;
  overflow: scroll;
  height: 74vh;
  padding-left: 4vw;
  padding-right: 4vw;
  -ms-overflow-style: none;
  /*scrollbar-width: none;*/
  overflow-x: hidden;
}
/*@media (max-width: 1280px) and (min-width: 600px) {*/
/*  .rightContainer{*/
/*    width: 81%;*/
/*  }*/
/*}*/
/*@media (max-width: 1366px) and (min-width: 1360px) {*/
/*  .rightContainer{*/
/*    width: 82%;*/
/*  }*/
/*}*/

.rightContainer::-webkit-scrollbar {
  width: 0.5vw;
  background-color: transparent;
}

.rightContainer::-webkit-scrollbar-thumb {
  background: var(--grey);
  border-radius: 10px;
}

.rightContainer::-webkit-scrollbar-button {
  display: none;
}

.surveySideBar {
  overflow: auto;
  height: 84vh;
}

.surveySideBar::-webkit-scrollbar {
  display: none;
}

.b_class {
  width: 59px;
  border: none !important;
}

.b_class span {
  height: 28px;
  width: 24px;
  background-size: contain;
  opacity: 0.5;
}

.i_class {
  width: 60px;
  border: none !important;
}

.i_class span {
  height: 28px;
  width: 24px;
  background-size: contain;
  opacity: 0.5;
}

.u_class {
  width: 60px;
  border: none !important;
}

.u_class span {
  height: 28px;
  width: 24px;
  background-size: contain;
  opacity: 0.5;
}

.find_replace_modalStyle {
  z-index: 1;
  right: 40px;
  top: 75px;
}

.paper {
  position: absolute;
  width: 17.3vw;
  background-color: #fff;
  box-shadow:0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 4vh;
  outline: none;
}

.mdltitle {
  border-bottom: solid 1px #f3f3f3;
  font-size: 20px;
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: -32px;
  margin-right: -32px;
  padding-bottom: 25px;
}

.closeModal {
  position:absolute;
  right: -12px;
  top: -12px;
  cursor:pointer;
  height: 5vh;
}

.inputSet {
  margin-bottom:5px;
}

.inputLabel {
  color:#999999;
  font-size: 1.5vh !important;
  margin-bottom: 1px;
  position: inherit !important;
}

.buttonRound {
  border-radius: 25px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
}

.find_replace_items {
  display: flex;
  height: 48vh;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.findAndReplaceModal {
  overflow: scroll;
}

.find_container label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.notranslate.public-DraftEditor-content{
  padding: 0px !important;
}

.highlight {
  background-color: yellow;
}

.toolbarClassName {
  position: absolute;
  top: 1vh;
  right: 0;
  width: 12.2vw;
}

#editableText {
  .rdw-history-wrapper, .rdw-inline-wrapper, .rdw-list-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 7vh;
  }
  
  .rdw-history-wrapper {
    border-bottom: 1px solid #ccc;
  }
  
  .rdw-inline-wrapper {
    padding: 0 1.8vw;
    border-bottom: 1px solid #ccc;
  }
  
  .rdw-history-wrapper .rdw-option-wrapper > img {
    height: 2.6vh;
    width: 1vw;
    opacity: 0.7;
  }
  
  .rdw-inline-wrapper .rdw-option-wrapper > img {
    height: 2vh;
    width: 1vw;
    opacity: 0.7;
  }
  
  .rdw-inline-wrapper .rdw-option-wrapper:first-child > img {
    height: 2vh;
    width: 1vw;
    opacity: 0.65;
  }
  
  .rdw-list-wrapper .rdw-option-wrapper > img {
    height: 22px;
    width: 20px;
    opacity: 0.7;
  }
  
  .splitContainer-left {
    float: left;
    width: 50%;
    border-right: 0.2vw solid #ccc;
    pointer-events: none;
  }
  
  .splitContainer-right {
    width: 50%;
    float: right;
  }
  
  .splitContainer-left .editorClassName.rdw-editor-main {
    width: 89%;
  }
  
  .splitContainer-right .editorClassName.rdw-editor-main {
    width: 89%;
    margin-left: 3vw;
  }
  
  .splitContainer-left .rdw-editor-toolbar.toolbarClassName {
    display: none;
  }
  
  .splitContainer-right .toolbarClassName {
    top: 0px;
  }
}


/* .toolbarClassName.toolbarClassNameHeightAdjusted {
  top: 73px;
} */

.wrapperClassName {
  text-align: left;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child {
  font-size: 1.92vh;
  font-family: 'Lato';
}

/* .addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child >div:first-child {
  border-top: none;
} */

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > div + ul + h6 {
  margin-left: 25px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  min-height: 0px !important;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > div + ul + h6 + ul {
  margin: 20px 0;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > div {
  display: flex;
  align-items: center;
  padding: 40px 0;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > ul > li {
  margin: 10px 0;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > div + ul {
  margin-top: -35px;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > h5 {
  min-height: 0px !important;
  border-top: 3px solid var(--grey);
  padding-top: 2.5vh;
  font-size: 2.4vh;
}

.addtional-question-editor .DraftEditor-root .DraftEditor-editorContainer .notranslate.public-DraftEditor-content > div:first-child > h5:first-child {
  border-top: none;
}

.wrapper.wrapperHeightAdjusted {
  margin-top: 50px;
}
.survey-ohi-editor {
  text-align: left;
}

.survey-ohi-editor .public-DraftEditor-content > div > h2 {
  font-size: 2.1vh;
  font-weight: bold;
}

.survey-ohi-editor .public-DraftEditor-content > div > div {
  margin-bottom: 3vh;
  line-height: 2.9vh;
}

.survey-ohi-editor .public-DraftEditor-content > div > ol {
  list-style-type: decimal-leading-zero;
}

.survey-ohi-editor .public-DraftEditor-content > div > ol:first-child {
  border-bottom: solid 3px var(--grey);
}

.survey-ohi-editor .public-DraftEditor-content > div > ol > li {
  height: auto;
  margin-bottom: 4vh;
  font-size: 2.9vh;
  line-height: 0.67;
  font-weight: 300;
  padding-bottom: 3vh;
}

.survey-ohi-editor .public-DraftEditor-content > div > ol > li:last-child {
  margin-bottom: 0vh;
}
.survey-ohi-editor .public-DraftEditor-content > div > ol > li > div {
  font-size: 1.914vh;
  font-style: normal;
  line-height: 1.5;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.survey-ohi-editor .public-DraftEditor-content > div:first-child > h2:nth-of-type(even) {
  border-top: solid 3px var(--grey);
  height : 10vh;
  display: flex;
  align-items: center;
}

.survey-ohi-editor .public-DraftEditor-content > div:first-child > h2:nth-of-type(odd) {
  margin-bottom: 4vh;
}

.rdw-option-wrapper {
  border: 1px solid var(--light-grey);
  padding: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-option-active {
  box-shadow: 1px 1px 2px 1px var(--dark) inset;
}

.modal-title {
  font-size: 1.9vh;
  color: var(--dark-charcoal-grey);
}

.button-round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6vw;
  height: 4.8vh;
  border-radius: 22.5px;
  border: solid 1px var(--light-cyan-blue);
  color: var(--light-cyan-blue);
  font-size: 1.7vh;
  font-weight: bold;
  margin-right: 1.2vw;
}

.button-round:hover {
  cursor: pointer;
}

.yes-button {
  background-color: var(--light-cyan-blue);
  color: white;
  width: 8.95vw;
}

.survey-ohi-editor .rdw-list-wrapper {
  pointer-events: none;
  opacity:0.3;
}

.addtional-question-editor .rdw-list-wrapper {
  pointer-events: none;
  opacity:0.3;
}

.introductionBorderClass {
  border: 0.12vw dashed var(--grey);
  margin: 0 0 4.8vh 0;
  padding: 1.2vh;
}

div.editorDottedBorder {
  border: 0.12vw dashed var(--grey);
  margin: 0 0 5vh 0;
  padding: 1.2vh !important;
}

li.editorDottedBorder {
  border: 0.12vw dashed var(--grey);
  margin: 0 0 4.8vh 0;
  padding: 1.2vh !important;
}

input:focus {
  outline: unset !important
}
.home-icon {
  margin-left: 1.8vw;
  height: 2.15vh;
  cursor: pointer;
}

.ohi-mckinsey-icon {
  width: 7.75vw;
  cursor: pointer;
}

.icon-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.icon-container.home-icon {
  border-left: solid 1px #0f3270;
}

.mainSectionGSDBPage input[type="text"]:disabled {
  background-color: transparent;
}

.error {
  color: red
}

.exportModal {
  overflow: scroll;
}

.export-modal-container {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.export_modalStyle {
  right: 40px;
  top: 200px;
}

.modal-paper {
  position: absolute;
  width: 23vw;
  background-color: #fff;
  box-shadow:0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 1.9vw;
  outline: none;
}

.subtitle-text {
  height: 1.2vh;
  font-size: 1.5vh;
  line-height: normal;
  color: var(--brown-grey)
}

.subtitle-text.tooltip-excel {
  display: flex;
  flex-direction: row;
  font-style: italic;
}

.subtitleTextContainer {
  width: 100%;
  margin-bottom: 6px;
}

.subtitle-text.language {
  margin-bottom: 6px;
}

.multiple-language-dropdown {
  width: 16.8vw;
  margin-left: 1.8vw;
}

.exportButton {
  width: 6.4vw;
  height: 5.4vh;
  border-radius: 22.5px !important;
  font-size: 1.8vh !important;
}

.importFileHidden {
  display: none;
}

.language-radioButton-container {
  margin-bottom: 2.5vh;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.rightToLeft {
  direction: rtl;
  text-align: right;
}

.export-instructions {
  margin-top: 5px;
}

.viewSurveyAnchor:focus, .viewSurveyAnchor:hover {
  text-decoration: none;
  color: inherit;
}

.import_Failure_Container {
  max-height: 450px;
  overflow: auto;
}
.inner_div_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

}

.surveys-steps .surveys-steps-list {
  margin: 2.2vh auto;
}

ol.surveys-steps {
  font-size: 2vh;
  font-weight: 700;
  padding-left: 1.5vw;
}

li.surveys-steps-list {
  padding-left: 0.6vw;
}

.survey-ohi-editor .link-unlink-container, .addtional-question-editor .link-unlink-container {
  display: none;
}

.link-modal-content {
  display: flex;
  flex-direction: column;
  height: 12vh;
}

.confirmButton {
  margin-top: 1.8vh !important;
  width: 6.5vw;
  height: 5vh;
  border-radius: 22.5px !important;
  font-size: 1.8vh !important;
}

.link-unlink-container {
  margin-top: 15vh;
}

.survey-introduction-editor .find-replace-container {
  margin-top: 0px !important;
}

.scrollbar-hidden {
  overflow-y: scroll;
}

.scrollbar-hidden .element::-webkit-scrollbar { width: 0 !important }

.scrollbar-hidden { overflow: -moz-scrollbars-none; }

.scrollbar-hidden { -ms-overflow-style: none; }

.scrollbar-hidden::-webkit-scrollbar {
  width: 1px;
  background-color: transparent;
}

.input-Label {
  font-size: 2vh !important;
}

.fixedHeightClass #date-picker-inline {
  font-size: 1.5vh;
}

.confirmation_popup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cross_icon {
  position: absolute;
  right: 1vw;
  top: 1.9vh;
  cursor: pointer;
}

.confirmation_popup_subtext {
  font-family: Lato;
  font-size: 2vh;
  font-weight: bold;
  color: black;
  margin-bottom: 0.3vh;
}

.button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20vw;
  margin-top: 2vh;
}

.round_button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: auto !important;
  height: 4.8vh !important;
  border-radius: 22.5px !important;
  color: #4b93fd !important;
  font-size: 1.7vh !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.round_button_container {
  color: white !important;
}

.custom_width {
  width: 15vw !important;
}

._text_loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #f2f2f2;
}

._text {
  position: relative;
  color: rgba(0, 0, 0, .2);
  font-size: 3em;
  font-weight: bold;
}

._text:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 7em;
  white-space: nowrap;
  color: #03131E;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  0% {
    max-width: 0;
  }
}

.intro-text {
  text-align: left;
  margin-bottom: 3vh;
  color: rgb(153, 153, 153) ;
}
.refress-btn{
  position: absolute;
  right: 15px;
  top: 20px;
}