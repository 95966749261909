$grey100: #dddddd;
$grey200: #e8e8e8;
$grey300: #414040;
$grey400: #e7e7e7;
$grey500: #999999;
$grey600: #666666;
$grey700: #333333;
$textBlue: #4b93fd;
$blue100: #ecf2fb;
$blue200: #d8eaff;
$black100: #000000;
$black200: #393939;
$themeOrange: #d63f1c;
$white: #fff;
$parrotGreen: #6ed70a;
$maroonRed: #d10000;
$navyBlue: #021c4b;
$lightBlue: #013362;
$orange: #f28165;

.jodit-container:not(.jodit_inline) {
  height: auto;
  width: auto;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

.emailmodal {
  padding: 40px 83px !important;

  p {
    margin-bottom: 35px !important;
  }
}

.duplicatetemplate {
  padding: 0 !important;

  h2 {
    padding: 30px 30px;
    border-bottom: solid 1px #f3f3f3;
    font-size: 20px;
    color: #393939;
  }

  .modalformpad {
    padding: 5px 30px 30px;
  }

  p {
    margin-bottom: 35px !important;
  }
}

.cstchipwraper {
  .cstchip {
    padding-left: 35px;

    .MuiChip-root {
      height: 25px;
      top: 5px;
    }
  }

  .chiplable {
    margin-top: 8px;
  }
}

.emailtextcontainer {
  text-align: left;
}

.lockimg {
  margin-right: 8px;
  margin-left: 5px;
  margin-bottom: 4px;
}

.sendpreview {
  input {
    font-size: 14px;
  }

  .MuiInputBase-root.Mui-disabled {
    input {
      padding-bottom: 0;
      padding-top: 13px;
    }

    &:before {
      display: none;
    }
  }
}

.disabledarea {
  background-color: #fafafa;
  margin-left: -100px;
  margin-right: -100px;
  padding: 0 100px;
  margin-top: 50px;
}

.editorcst {
  .rdw-link-modal {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: initial;
  }

  .rdw-link-modal-label {
    width: 90px;
    box-sizing: border-box;
    padding-top: 9px;
  }

  .rdw-link-modal-input {
    width: calc(100% - 100px);
    box-sizing: border-box;
    height: 37px;
  }

  .rdw-link-modal-buttonsection {
    width: 100%;
    box-sizing: border-box;
  }
  .demo-editor.rdw-editor-main {
    border: dashed 2px #dddddd !important;
    border-radius: 0 !important;
  }
  .rdw-editor-toolbar {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-color: #dddddd !important;
    margin-bottom: 0;
    border-bottom: none;
  }
}
.survey-introduction-editor {
  .public-DraftStyleDefault-ul {
    margin-bottom: 25px;
    li {
      height: auto;
      margin-bottom: 4vh;
      border: 0.12vw dashed var(--grey);
      margin: 0 0 3vh 0;
      padding: 1.2vh !important;
    }
  }
}
